import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
// import fetchClient from './axiosInstance';
// import reportsApi from './reportsApi';
import { fetchClient, reportsApiClient as reportsApi } from './interceptor';
interface SalesStatisticsQueryParams extends PageQueryParams {
  months: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

interface SalesStatisticsReportsQueryParams extends PageQueryParams {
  // months?: string;
  period_1_after: string;
  period_1_before: string;
  city_id?: string | number;
  brand_id?: string;
  barcode?: string;
  local_company_id?: string;
  size_of_package_id?: string;
  subcategory_id?: string;
  type_of_package_id?: string;
  active_stores?: string | boolean;
  common_stores?: boolean | string;
  battery_capacity_id?: string;
  validated_stores?: boolean;
}

interface ScanDataReportsQueryParams {
  months: string;
  city_id?: string | number;
  brand_id?: string;
  barcode?: string;
  local_company_id?: string;
  size_of_package_id?: string;
  subcategory_id?: string;
  type_of_package_id?: string;
  active_stores?: string | boolean;
  common_stores?: boolean | string;
  battery_capacity_id?: string;
  validated_stores?: boolean;
}

export default class SalesStatisticsService {
  static async getSalesStatisticsByCompany(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/local_company/`, { params, ...customConfig });
  }

  static async getSalesStatisticsReportsByCompany(
    params: SalesStatisticsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/sales_statistics/local_company`, { params, ...customConfig });
  }

  static async getExtrapolationSalesStatisticsByCompany(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/local_company/extrapolation/`, { params, ...customConfig });
  }

  static async getSalesStatisticsByBrand(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/brand/`, { params, ...customConfig });
  }

  static async getSalesStatisticsReportsByBrand(
    params: SalesStatisticsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/sales_statistics/brand`, { params, ...customConfig });
  }

  static async getExtrapolationSalesStatisticsByBrand(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/brand/extrapolation/`, { params, ...customConfig });
  }

  static async getSalesStatisticsByBarcode(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/barcode/`, { params, ...customConfig });
  }

  static async getSalesStatisticsReportsByBarcode(
    params: SalesStatisticsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/sales_statistics/barcode`, { params, ...customConfig });
  }

  static async getExtrapolationSalesStatisticsByBarcode(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/barcode/extrapolation/`, { params, ...customConfig });
  }

  static async getReportsExtrapolationSalesStatistics(
    type: string,
    params: SalesStatisticsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/sales_statistics/extrapolation/${type}`, { params, ...customConfig });
  }

  static async exportByType(
    type: string,
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportReportsByType(
    type: string,
    params: SalesStatisticsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/sales_statistics/export/${type}`, {
      params,
      ...customConfig,
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportExtrapolationByType(
    type: string,
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/${type}/extrapolation_export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportExtrapolationReportsByType(
    type: string,
    params: SalesStatisticsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/sales_statistics/extrapolation_export/${type}`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportScanData(
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/scan_data/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportReportsScanData(
    params: ScanDataReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/scan_data/`, {
      params,
      ...customConfig,
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportNewByType(
    type: string,
    params: SalesStatisticsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/sales_statistic/${type}/export/`, { params, ...customConfig });
  }
}
